import React, { useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import { FormikConfig, FormikProps } from 'formik';
import { AxiosError } from 'axios';

import {
    grid,
    titleText,
    subtitleText,
    contentText,
    successBox,
    subtitleSuccess,
    contentSuccess,
} from './newsletter.module.scss';

import { INewsletterFormValues } from '../../models/newsletter-form.model';
import { ISection } from '../../models/section.model';
import { IErrorResponse } from '../../models/api-models.model';
import { handleFormikErrors } from '../../utils/get-form-errors';
import { postNewsletter } from '../../api-clients/newsletter';
import useTranslations from '../../hooks/use-translations';

import Section from '../hoc/section';
import NewsletterForm from '../organisms/newsletter-form';

interface INewsletterSection extends ISection {
    items: {
        newsletter: {
            newsletterId: number;
        };
    };
}

interface INewsletterProps {
    className?: string;
    section: INewsletterSection;
    TitleTag?: React.ElementType;
}

const Newsletter: React.FC<INewsletterProps> = ({ className = '', section, TitleTag = 'h2' }) => {
    const {
        sectionId,
        content: { texts, buttons },
        css,
        style,
        items,
    } = section;
    const newsletterId = items.newsletter.newsletterId;
    const t = useTranslations('Newsletter');
    const successBoxRef = useRef<HTMLDivElement>(null);

    const formikRef = useRef<FormikProps<INewsletterFormValues>>(null);
    const mutation = useMutation(postNewsletter, {
        onError: handleMutationError,
        onSuccess: handleSuccess,
    });
    const { isSuccess, isLoading } = mutation;

    const handleSubmit: FormikConfig<INewsletterFormValues>['onSubmit'] = (values) => {
        mutation.mutate({ ...values, newsletter: newsletterId });
    };

    function handleSuccess() {
        if (!successBoxRef.current) return;
        successBoxRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    function handleMutationError(error: AxiosError<IErrorResponse>) {
        handleFormikErrors(formikRef.current, error, {
            network: t.error.network,
            global: t.error.global,
        });
    }

    return (
        <Section
            sectionId={sectionId}
            className={className}
            classes={{
                container: grid,
                title: titleText,
                subtitle: subtitleText,
                description: contentText,
            }}
            {...getTexts(texts, isSuccess)}
            TitleTag={TitleTag}
            css={css}
            style={style}
        >
            {!isSuccess && (
                <NewsletterForm
                    onSubmit={handleSubmit}
                    formikRef={formikRef}
                    submitText={buttons[0]?.text}
                    consent={texts[3] ?? ''}
                    submitLoading={isLoading}
                />
            )}
            {isSuccess && (
                <div className={successBox} ref={successBoxRef}>
                    <p className={subtitleSuccess}>{t.success.title}</p>
                    <p className={contentSuccess}>{t.success.subtitle}</p>
                </div>
            )}
        </Section>
    );
};

function getTexts(texts: (string | null)[], isSuccess?: boolean) {
    const [title, subtitle, description] = texts;

    if (!isSuccess) {
        return { title, subtitle, description };
    }

    return { title };
}

export default Newsletter;
